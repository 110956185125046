.footer-container {
    position: relative;
}

.footer-container>hr {
    border: 1px solid var(--lightgray);
}

.footer {
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: center;
    justify-content: center;
    height: 20rem;
}

.social-links {
    display: flex;
    gap: 4rem;
}

.social-links>img {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}

.logo>img {
    width: 10rem;
}

.blur-f1 {
    width: 45rem;
    height: 22rem;
    bottom: 0;
    right: 10%;
    filter: blur(400px);
    background: rgba(255, 115, 0, 0.29);
}

.blur-f2 {
    width: 45rem;
    height: 22rem;
    bottom: 0;
    left: 10%;
    filter: blur(400px);
    background: rgba(255, 115, 0, 0.29);
}

/* ---------------- for mobile devices ----------------- */

@media screen and (max-width: 768px) {
    .logo-f>img {
        width: 15rem;
    }
}